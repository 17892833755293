import React from "react";

import { FiGithub } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";

import { SiLeetcode } from "react-icons/si";

const MobileNavbar = () => {
  return (
    <div className="rest-icons flex flex-col text-xl z-50 pb-5 w-screen  sm:hidden ">
      <div className="options flex flex-col items-start max-sm:text-md ">
        <button
          className="text-gray-300 hover:text-white"
          onClick={() => {
            const element = document.querySelector("#home");
            console.log(element);
            element?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          Home
        </button>
        <button
          className="text-gray-300 hover:text-white"
          onClick={() => {
            const element = document.querySelector("#skills");
            element?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          Skills
        </button>
        <button
          className="text-gray-300 hover:text-white"
          onClick={() => {
            const element = document.querySelector("#projects");
            element?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          Projects
        </button>
      </div>
      <div className="icons flex gap-4 items-center">
        <a
          href="https://www.linkedin.com/in/mayurhanwate/"
          target="_blank"
          className="group border border-2 rounded-full hover:motion-safe:animate-pulse p-2 inline-block hover:bg-white hover:text-black "
          style={{ display: "inline-block", position: "relative" }}
        >
          <FaLinkedinIn className="hover:text-black transition-colors duration-300" />
        </a>
        <a
          href="https://github.com/lordbakyarou"
          target="_blank"
          className="group border border-2 rounded-full p-1 hover:motion-safe:animate-pulse inline-block hover:bg-white hover:text-black "
        >
          <FiGithub className="hover:text-black transition-colors duration-300 text-2xl" />
        </a>
        <a
          href="https://leetcode.com/mayur1710hanwate/"
          target="_blank"
          className="group border border-2 rounded-full p-2 hover:motion-safe:animate-pulse inline-block hover:bg-white hover:text-black "
        >
          <SiLeetcode className="hover:text-black transition-colors duration-300" />
        </a>

        {/* <button
          onClick={() => {
            const element = document.querySelector("#connect");
            element?.scrollIntoView({
              behavior: "smooth",
            });
          }}
          class="relative  border-2 border-white bg-transparent p-3 font-medium  text-white font-semibold transition-colors before:absolute before:left-0 before:top-0 before:-z-40 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-white  before:transition-transform before:duration-300 before:text-[none] hover:text-black hover:z-40 before:hover:scale-x-100"
        >
          Let’s Connect
        </button> */}

        <div
          className="sm:hidden cursor-pointer"
          onClick={() => {
            const element = document.querySelector("#connect");
            element?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <div
            className="overflow-hidden border-[4px] border-transparent p-1 rounded-lg "
            style={{
              fontFamily: "Roboto, sans-serif",
              background:
                "linear-gradient(90deg, #EC37D0, #D92B2B, #FFAA0D, #EC37D0)",
              backgroundSize: "400%",
              animation: "animate 4s linear infinite",
              margin: 0,
              padding: 0,
              width: "fit",
              height: "fit",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div class="bg-black p-2">
              <div class="">Lets Connect</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
