import React from "react";

import { FaArrowRight } from "react-icons/fa6";

import { FiGithub } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";

import { SiLeetcode } from "react-icons/si";

import { FaBars } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="pt-10 pb-10 gap-5 flex justify-center items-center flex-col bg-black">
      <div className="flex justify-between w-full px-10 ">
        <div
          className="logo font-semibold text-2xl max-sm:text-lg flex items-center text-white cursor-pointer"
          onClick={() => {
            const element = document.querySelector("#home");
            console.log(element);
            element?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          Contact Me
        </div>
        <div className="icons flex gap-4 items-center">
          <a
            href="https://www.linkedin.com/in/loodaysofcode-challenge-bb611a278/"
            target="_blank"
            className="group border border-2 rounded-full hover:motion-safe:animate-pulse p-2 hover:bg-white hover:text-black inline-block text-white"
            style={{ display: "inline-block", position: "relative" }}
          >
            <FaLinkedinIn className="hover:text-black transition-colors duration-300" />
          </a>
          <a
            href="https://github.com/lordbakyarou"
            target="_blank"
            className="group border border-2 rounded-full p-1 hover:motion-safe:animate-pulse hover:text-black hover:bg-white inline-block text-white "
          >
            <FiGithub className="hover:text-black transition-colors duration-300 text-2xl" />
          </a>
          <a
            href="https://leetcode.com/mayur1710hanwate/"
            target="_blank"
            className="group border border-2 rounded-full p-2 hover:motion-safe:animate-pulse hover:text-black hover:bg-white inline-block text-white "
          >
            <SiLeetcode className="hover:text-black transition-colors duration-300" />
          </a>
        </div>
      </div>
      <div className="flex w-full">
        <div className="text-white w-full justify-start flex pl-10 max-md:text-sm max-sm:text-[10px] max-xxs:text-[7px]">
          Email : mayur1710hanwate@gmail.com
        </div>
        <div className="text-white w-full justify-end flex pr-10 max-md:text-sm max-sm:text-[10px] max-xxs:text-[7px]">
          Copyright © 2024 Lordbakayarou
        </div>
      </div>
    </div>
  );
};

export default Footer;
