import React from "react";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

const Journey = () => {
  return (
    <Timeline position="alternate">
      <div className="w-full flex justify-center pb-10">
        <p className="text-white text-4xl font-semibold">My Timeline</p>
      </div>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className="text-white">
          <p>2021-07-01</p>
          <p>Graduated, BE Information Technology</p>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className="text-white">
          <p>2021-08-09</p>
          <p>
            Got my first job as Functional Test Engineer{" "}
            <a
              href="https://www.linkedin.com/company/amdocs/"
              target="_blank"
              className="text-indigo-300"
            >
              @Amdocs
            </a>
          </p>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className="text-white">
          <p>2022-05-09</p>
          <p>Got promoted as DWH/BI Test</p>
          <p>
            Engineer,{" "}
            <a
              href="https://www.linkedin.com/company/amdocs/"
              target="_blank"
              className="text-indigo-300"
            >
              @Amdocs
            </a>
          </p>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className="text-white">
          <p>2023-02-01</p>
          <p>Became AWS Certified Solutions Architect</p>
          <a
            href="https://www.linkedin.com/company/amazon-web-services/"
            target="_blank"
            className="text-indigo-300"
          >
            @Amazon Web Services
          </a>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent className="text-white">
          <p>2023-03-01</p>
          <p>Started my Fullstack Developer Journey</p>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default Journey;
