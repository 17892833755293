import React from "react";

import instagram from "../assets/img/Cover (2).png";
// import instagram from "../assets/img/file cover - 2 (2).png";
import unpause from "../assets/img/thumb nail (4).png";
import youtube from "../assets/img/file cover - 1.png";
import pixelart from "../assets/img/Thumbnail.png";

import { NavLink } from "react-router-dom";

import { FaArrowRight } from "react-icons/fa6";

const Projects = () => {
  return (
    <div
      className="flex flex-col bg-black items-center pt-20 gap-10 pb-10 "
      id="projects"
    >
      <div className="text-white text-5xl font-semibold">Projects</div>
      <div className="text-white w-1/2 max-lg:w-2/3 text-gray-400 text-lg  max-xxs:p-4 max-xxs:w-full">
        My developer journey started as a college major project (Real estate
        app). Through challenges like learning syntax to understanding other
        people code and being consistent with showing up ever day, I learned
        collaboration and teamwork and the power of community. Projects like
        Instagram Clone fueled my passion for becoming Fullstack developer. It's
        a story that's far from over, a story fueled by ambition and a desire to
        make a difference. So, join me! Let's build something amazing, together.
      </div>
      <div className="projects flex grid grid-cols-2 xl:grid-cols-3 max-md:grid-cols-1 gap-10 justify-center">
        <NavLink to="https://rb.gy/aw0wnj" target="_blank">
          <div className="relative w-96 h-80 max-lg:w-80 max-lg:h-72 max-md:w-96 max-sm:w-80 max-sm:h-72  max-md:h-80 bg-white border border-2 rounded rounded-xl overflow-hidden cursor-pointer">
            <img src={instagram} className="object-cover w-full h-full" />
            <div className="absolute inset-0 bg-gradient-to-b from-black hover:opacity-75 to-black opacity-0 hover:opacity-100 flex items-center justify-center  duration-100 ">
              <p className="text-white text-lg flex flex-col justify-center items-center font-bold opacity-100">
                Instagram Clone
                <span>Fullstack MERN Application</span>
              </p>
            </div>
          </div>
        </NavLink>

        <NavLink to="https://www.unpause.fun/" target="_blank">
          <div className="relative w-96 h-80 max-lg:w-80 max-lg:h-72 max-md:w-96 max-sm:w-80 max-sm:h-72 max-md:h-80 bg-white border border-2 rounded rounded-xl overflow-hidden cursor-pointer">
            <img src={unpause} className="object-cover w-full h-full" />
            <div className="absolute inset-0 bg-gradient-to-b from-black hover:opacity-75 to-black opacity-0 hover:opacity-100 flex items-center justify-center  duration-100 ">
              <p className="text-white text-lg flex flex-col justify-center items-center font-bold opacity-100">
                Unpause
                <span>Fullstack ReactJS Firebase Application</span>
              </p>
            </div>
          </div>
        </NavLink>
        <a href="https://lordbakyarou.github.io/YoutubeClone/" target="_blank">
          <div className="relative w-96 h-80 max-lg:w-80 max-lg:h-72 max-md:w-96 max-sm:w-80 max-sm:h-72 max-md:h-80 bg-white border border-2 rounded rounded-xl overflow-hidden cursor-pointer">
            <img src={youtube} className="object-cover w-full h-full" />
            <div className="absolute inset-0 bg-gradient-to-b from-black hover:opacity-75 to-black opacity-0 hover:opacity-100 flex items-center justify-center  duration-100 ">
              <p className="text-white text-lg flex flex-col justify-center items-center font-bold opacity-100">
                Youtube Clone
                <span>Vanila JS</span>
              </p>
            </div>
          </div>
        </a>
        <a
          href="https://lordbakyarou.github.io/PixelArt-Drawing-Board/"
          target="_blank"
        >
          <div className="relative w-96 h-80 max-lg:w-80 max-lg:h-72 max-md:w-96 max-sm:w-80 max-sm:h-72 max-md:h-80 bg-white border border-2 rounded rounded-xl overflow-hidden cursor-pointer">
            <img src={pixelart} className="object-cover w-full h-full" />
            <div className="absolute inset-0 bg-gradient-to-b from-black hover:opacity-75 to-black opacity-0 hover:opacity-100 flex items-center justify-center  duration-100 ">
              <p className="text-white text-lg flex flex-col justify-center items-center font-bold opacity-100">
                Pixel Art
                <span>Vanila JS</span>
              </p>
            </div>
          </div>
        </a>
      </div>
      <div>
        <a
          className="p-2 px-5 rounded-full text-gray-200 bg-gray-600 items-center max-sm:text-sm gap-2 flex w-fit cursor-pointer transition-all duration-300 hover:scale-110"
          href="https://github.com/lordbakyarou"
          target="_blank"
        >
          Check out my github for more projects
          <FaArrowRight className="-rotate-45" />
        </a>
      </div>
    </div>
  );
};

export default Projects;
