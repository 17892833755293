import React from "react";
import Button from "@mui/material/Button";
import { blueGrey } from "@mui/material/colors";

import { techStack } from "../constants";

const colors = blueGrey[500];

const Skills = () => {
  return (
    <div
      className="flex items-center flex-col gap-5 justify-center bg-black pt-20 pb-10"
      id="skills"
    >
      <p className="text-white text-4xl font-semibold">Tech</p>
      <div className="flex flex-wrap gap-3 grid grid-cols-5 max-md:grid-cols-5 max-sm:grid-cols-3 max-xxs:grid-cols-2 justify-center font-semibold ">
        {techStack.map((item, index) => {
          return (
            <button
              key={index}
              className="p-2 rounded text-white w-24 h-30 bg-gray-600 hover:animate-waving-hand flex flex-col items-center justify-between"
              style={
                {
                  // background: "rgba(255, 255, 255, 0.5)",
                }
              }
            >
              <img src={item.link} />
              <p>{item.name}</p>
            </button>
          );
        })}
        {/* <button className=" p-2 rounded text-white w-20 h-20 flex flex-col items-center">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
          <p>Javascript</p>
        </button>
        <button className=" p-2 rounded text-black bg-white font-semibold">
          <span
            style={{
              color: "#009026",
            }}
          >
            M
          </span>
          <span>E</span>
          <span
            style={{
              color: "#01dbff",
            }}
          >
            R
          </span>
          <span
            style={{
              color: "#50be3b",
            }}
          >
            N
          </span>
        </button>
        <button
          className=" p-2 rounded text-white"
          style={{ backgroundColor: "#ff7108" }}
        >
          HTML
        </button>
        <button
          className=" p-2 rounded text-white"
          style={{
            backgroundColor: "#0875c1",
          }}
        >
          CSS
        </button>
        <button
          className=" p-2 rounded text-white"
          style={{ backgroundColor: "#38BDF8" }}
        >
          Tailwind CSS
        </button>
        <button
          className="p-2 rounded text-white"
          style={{
            backgroundColor: "#e07127",
          }}
        >
          SQL
        </button>

        <button className="bg-gray-600 p-2 rounded text-white">AWS</button>
        <button
          className="p-2 rounded text-white"
          style={{
            backgroundColor: "#547c99",
          }}
        >
          Java
        </button> */}
      </div>
    </div>
  );
};

export default Skills;
