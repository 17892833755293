import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import contactUs from "../assets/img/5124556.png";

const Connect = () => {
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (name && message && email) {
      setLoading(true);
      emailjs
        .sendForm("service_w286wrr", "template_d9egodh", form.current, {
          publicKey: "Nbcdw8_V4_MJ2TtRk",
        })
        .then(
          () => {
            setLoading(false);
            setName("");
            setEmail("");
            setMessage("");
          },
          (error) => {
            console.log("FAILED...", error.text);
            setName("");
            setEmail("");
            setMessage("");
            setLoading(false);
          }
        );
    } else {
      console.log("please enter all fields");
      setError(true);
      setTimeout(() => setError(false), 1000);
    }
  };
  return (
    <div
      className=""
      id="connect"
      style={{
        fontFamily: "Roboto, sans-serif",
        background:
          "linear-gradient(90deg, #EC37D0, #D92B2B, #FFAA0D, #EC37D0)",
        backgroundSize: "400%",
        animation: "animate 20s linear infinite",
        margin: 0,
        padding: 0,
        width: "fit",
        height: "fit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div
        style={{ position: "relative" }}
        className="flex gap-10 max-lg:gap-5 items-center justify-center pt-10 pb-10 max-md:flex-col "
      >
        <div className="w-full lg:w-auto flex flex-1 justify-start h-fit gap-10 max-lg:pt-0 pt-10 justify-self-start animate-trans-up ">
          <img
            src={contactUs}
            style={{
              maxWidth: "500px",
              maxHeight: "500px",
              objectFit: "contain",
            }}
            className="max-xl:w-96 max-lg:w-80 max-md:w-96 max-sm:w-72"
          />
        </div>

        <div className="content flex flex-col gap-5">
          <div className="text-5xl font-semibold text-white">Get in touch</div>
          <form ref={form} onSubmit={sendEmail} className="flex flex-col gap-5">
            <div className="flex gap-5 max-lg:gap-2">
              <input
                type="text"
                placeholder="Full Name"
                className="w-[600px] h-16 max-xl:w-[500px] max-sm:w-[400px] max-xxs:w-[320px]  max-lg:w-[400px] max-md:w-[500px] border border-2 rounded-xl p-3 outline-none bg-transparent text-white border border-white  font-semibold border-opacity-20 placeholder-gray-200"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                }}
                name="user_name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                autoComplete="off"
              />
            </div>
            <div className="flex gap-5 max-lg:gap-2">
              <input
                type="text"
                placeholder="Email Address"
                className="w-[600px] h-16 max-xl:w-[500px] max-lg:w-[400px] max-xxs:w-[320px] backdrop-blur-2xl max-md:w-[500px] max-sm:w-[400px] border border-2 rounded-xl p-3 outline-none bg-transparent text-white border border-white  font-semibold border-opacity-20 placeholder-gray-200"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                }}
                name="user_email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                autoComplete="off"
              />
            </div>
            <div className="w-full">
              <textarea
                placeholder="Message"
                className="w-full h-40 rounded-xl p-3 outline-none border bg-transparent text-white border-white  font-semibold border-opacity-20 placeholder-gray-200"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                }}
                name="message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                autoComplete="off"
              />
            </div>
            <button
              style={{
                background: "rgba(255, 255, 255, 0.1)",
              }}
              type="submit"
              className={` ${loading && "animate-pulse"} ${
                error && "animate-pulse"
              } relative w-fit px-9 border-2 border-white border-opacity-20 bg-transparent py-2.5 px-5 font-medium uppercase text-white font-semibold transition-colors before:absolute before:left-0 before:top-0 before:-z-40 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-white before:transition-transform before:duration-300 before:text-[none] hover:text-black hover:z-40 before:hover:scale-x-100`}
            >
              {loading ? "Sending..." : error ? "Invalid Fields" : "Send"}
            </button>
          </form>
        </div>
      </div>
      <style>
        {`
          /* CSS for hover effect */
          
          @keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
        `}
      </style>
    </div>
  );
};

export default Connect;
