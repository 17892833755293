import React, { useEffect, useRef, useState } from "react";

import { FiGithub } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";

import { SiLeetcode } from "react-icons/si";

import { FaBars } from "react-icons/fa";

import logo from "../assets/img/42084.png";
import MobileNavbar from "./MobileNavbar";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  const [mobileView, setMobileView] = useState(false);

  const [onScrollCode, setOnScrollCode] = useState(
    "text-white flex  w-full px-40 py-4 items-center sticky top-0 z-50  bg-black   max-xl:justify-between max-xl:px-10"
  );

  const [onScrollUpCode, setOnScrollUpCode] = useState(
    "text-white flex w-full px-40 w-full  py-4 items-center sticky top-0 z-50 max-xl:justify-between max-xl:px-10"
  );

  const homeLinkRef = useRef(null);
  const projectRef = useRef(null);

  const handleHomeClick = (e) => {
    e.preventDefault();
    const element = homeLinkRef.current;

    if (element) {
      // Calculate distance to top of the element
      const offsetTop = element.getBoundingClientRect().top + window.scrollY;

      // Use window.scrollTo for wider browser compatibility
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    function onScroll() {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div
      className={`transition-all  duration-1000 ${
        scrolled ? onScrollCode : onScrollUpCode
      }
      ${mobileView ? "top-0 left-0 w-full z-50" : ""}`}
    >
      <div
        className="logo font-semibold text-4xl cursor-pointer"
        onClick={() => {
          const element = document.querySelector("#home");
          console.log(element);
          element?.scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        MAYUR
      </div>
      <div className="rest-icons flex gap-10 items-center text-xl flex-1 justify-end max-lg:gap-5">
        <div className="options flex gap-10 items-center max-lg:gap-5 max-sm:hidden">
          <button
            className="text-gray-300 hover:text-white"
            onClick={() => {
              const element = document.querySelector("#home");
              console.log(element);
              element?.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            Home
          </button>
          <button
            className="text-gray-300 hover:text-white"
            onClick={() => {
              const element = document.querySelector("#skills");
              element?.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            Skills
          </button>
          <button
            className="text-gray-300 hover:text-white"
            onClick={() => {
              const element = document.querySelector("#projects");
              element?.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            Projects
          </button>
        </div>
        <div className="icons flex gap-4 items-center max-lg:absolute max-lg:w-full max-lg:justify-center max-lg:top-16 max-lg:hidden">
          <a
            href="https://www.linkedin.com/in/mayurhanwate/"
            target="_blank"
            className="group border border-2 rounded-full hover:motion-safe:animate-pulse p-2 inline-block hover:bg-white hover:text-black "
            style={{ display: "inline-block", position: "relative" }}
          >
            <FaLinkedinIn className="hover:text-black transition-colors duration-300" />
          </a>

          <a
            href="https://github.com/lordbakyarou"
            target="_blank"
            className="group border border-2 rounded-full p-1 hover:motion-safe:animate-pulse inline-block hover:bg-white hover:text-black "
          >
            <FiGithub className="hover:text-black transition-colors duration-300 text-2xl" />
          </a>
          <a
            href="https://leetcode.com/mayur1710hanwate/"
            target="_blank"
            className="group border border-2 rounded-full p-2 hover:motion-safe:animate-pulse inline-block hover:bg-white hover:text-black "
          >
            <SiLeetcode className="hover:text-black transition-colors duration-300" />
          </a>
        </div>
        <div className="flex ">
          {/* <div className="lets-connect  max-sm:hidden">
            <button
              onClick={() => {
                const element = document.querySelector("#connect");
                element?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
              class="relative  border-2 border-white bg-transparent p-3 font-medium  text-white font-semibold transition-colors before:absolute before:left-0 before:top-0 before:-z-40 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-white  before:transition-transform before:duration-300 before:text-[none] hover:text-black hover:z-40 before:hover:scale-x-100"
            >
              Let’s Connect
            </button>
          </div> */}
          {/* <div class="relative inline-flex  animate-pulse group w-fit">
            <div class="absolute transitiona-all duration-1000 opacity-70 outline-none -inset-px bg-gradient-to-r from-[#b144ff] via-[#8944ff] to-[#44BCFF] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
            <a
              href="https://drive.google.com/file/d/1kwnE4fskwF3r2rLeXP08wBIpHRh-upXy/view?usp=sharing"
              target="_blank"
              class="relative inline-flex items-center outline-none  justify-center px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-transparent font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-gray-900"
              role="button"
            >
              Let's Connect
            </a>
          </div> */}

          <div
            className="max-sm:hidden cursor-pointer"
            onClick={() => {
              const element = document.querySelector("#connect");
              element?.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            <div
              className="overflow-hidden border-[4px] border-transparent p-1 rounded-lg "
              style={{
                fontFamily: "Roboto, sans-serif",
                background:
                  "linear-gradient(90deg, #EC37D0, #D92B2B, #FFAA0D, #EC37D0)",
                backgroundSize: "400%",
                animation: "animate 4s linear infinite",
                margin: 0,
                padding: 0,
                width: "fit",
                height: "fit",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <div className="bg-black p-2">
                <div className="">Lets Connect</div>
              </div>
            </div>
          </div>

          <div className="sm:hidden cursor-pointer text-2xl">
            <FaBars onClick={() => setMobileView(!mobileView)} />
          </div>
        </div>
      </div>
      <div
        className={`absolute flex justify-start w-screen pl-10 left-0 transition-all duration-1000 ${
          mobileView
            ? `translate-y-5 ${scrolled ? "bg-black" : ""}`
            : `-translate-y-10 ${scrolled ? "bg-black" : ""}`
        }`}
        style={{
          top: "52.1px",
          zIndex: "0",
        }}
      >
        {mobileView && <MobileNavbar />}
      </div>
    </div>
  );
};

export default Navbar;
