import React from "react";

//Css import
import "../src/App.css";

//Components import
import Navbar from "./Components/Navbar";
import Banner from "./Components/Banner";
import Projects from "./Components/Projects";
import Connect from "./Components/Connect";

import bannerImg from "../src/assets/img/banner-bg.jpg";
import Journey from "./Components/Journey";
import Skills from "./Components/Skills";
import Footer from "./Components/Footer";

const App = () => {
  return (
    <div className="font-sans ">
      <div
        className="bg-black w-screen h-fit relative flex flex-col bg-no-repeat drop-shadow-2xl "
        style={{
          backgroundImage: `url(${bannerImg})`,
        }}
      >
        <Navbar />
        <Banner className="snap-start" />
        <Projects className="snap-start" />

        <Skills />
        <div className="bg-black pb-10 pt-10">
          <Journey />
        </div>

        <Connect />
        <Footer />
      </div>
    </div>
  );
};

export default App;
