import React, { useEffect, useState } from "react";

import bannerImg from "../assets/img//banner-bg.jpg";

import { FaArrowRightLong } from "react-icons/fa6";

import animatedLogo from "../assets/img/header-img.png";
import { FaLinkedinIn } from "react-icons/fa";

import resume from "../assets/img/MayurHanwateResume.pdf";

const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [bannerText, setBannerText] = useState("");
  const [index, setIndex] = useState(1);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 2000;

  const toRotate = [
    "Javascript Developer",
    "MERN Developer",
    "Fullstack Developer",
    "ReactJs Developer",
  ];

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div
      className=" w-screen flex pb-10 bg-gradient-to-b from-transparent to-black"
      id="home"
    >
      <div className="flex justify-between w-full max-md:flex-col max-lg:justify-center gap-10">
        <div className="w-full lg:w-auto flex flex-col gap-10 pt-44 pl-40 max-lg:pl-10 max-lg:item-center max-sm:pl-2">
          <div className="text-white w-fit text-2xl font-semibold max-sm:text-xl">
            <p className="border p-2 inline-block flex gap-1">
              Welcome to my portfolio
            </p>
          </div>

          <div className="flex flex-col gap-5">
            <p className="text-white text-6xl font-semibold max-w-2xl max-sm:text-4xl">
              Hi! I'm Mayur {text}
              <span className="text-gray-400 text-7xl font-normal">|</span>
            </p>
            <p className="text-gray-200 text-lg max-w-2xl max-w-lg max-sm:text-md">
              Lines of code aren't just strings of characters to me; they're the
              building blocks of dreams. Every day, life hands us a
              participation ticket, not a guarantee of victory. But that doesn't
              deter me. My 'win' isn't just a single destination, it's the
              ongoing journey of growth, learning, and impact.I'm not just a
              developer, I'm a problem solver, a collaborator who thrives in the
              energy of a buzzing team.
            </p>
          </div>

          {/* <div className="text-white text-lg w-fit flex items-center gap-4 group">
            <p className="font-semibold cursor-pointer">Let's Connect</p>
            <a
              href="https://www.linkedin.com/in/loodaysofcode-challenge-bb611a278/"
              target="_blank"
              rel="noopener noreferrer"
              className="transform transition-transform ease-in-out duration-500 group-hover:translate-x-10"
            >
              <FaArrowRightLong className="border rounded-full border-2 font-semibold p-1 text-3xl cursor-pointer" />
            </a>
          </div> */}

          <div className="relative inline-flex  group w-fit">
            <div className="absolute transitiona-all duration-1000 opacity-70 outline-none -inset-px bg-gradient-to-r from-[#b144ff] via-[#8944ff] to-[#44BCFF] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
            <a
              href={resume}
              target="_blank"
              className="relative inline-flex items-center outline-none  justify-center px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-transparent font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-gray-900"
              role="button"
            >
              Resume
            </a>
          </div>
        </div>
        <div className="w-full lg:w-auto flex flex-1 h-fit gap-10 pt-40 max-md:pt-0 pr-40 max-lg:pr-0 animate-trans-up justify-end max-lg:justify-center max-md:pt-0 ">
          <img
            src={animatedLogo}
            alt="Animated Logo"
            style={{
              maxWidth: "500px",
              maxHeight: "500px",
              objectFit: "contain",
            }}
            className="max-xl:w-96 max-lg:w-80 max-md:w-96"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
